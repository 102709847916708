import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { AuthGuard, ResourceGuard, RoleGuard } from '@/routes/guard';
import { NavLayout } from '@/routes/layout';

const AgenciesPage = lazy(() => import('@/pages/agencies'));
const AgencyHomePage = lazy(() => import('@/pages/agencies/[agency_id]'));
const ManageStorePage = lazy(() => import('@/pages/agencies/[agency_id]/stores'));
const ManageMarketerPage = lazy(() => import('@/pages/agencies/[agency_id]/marketers'));
const ManageSettlementPage = lazy(() => import('@/pages/agencies/[agency_id]/settlements'));
const SettingPage = lazy(() => import('@/pages/agencies/[agency_id]/setting'));
const NoticeListPage = lazy(() => import('@/pages/agencies/[agency_id]/notices'));
const NoticeDetailPage = lazy(() => import('@/pages/agencies/[agency_id]/notices/[notice_id]'));
const AdvertisementDashboardPage = lazy(() => import('@/pages/agencies/[agency_id]/dashboard/advertisement'));
const SalesDashboardPage = lazy(() => import('@/pages/agencies/[agency_id]/dashboard/sales'));
const PenaltiesPage = lazy(() => import('@/pages/agencies/[agency_id]/penalties'));

export const agencies: RouteObject[] = [
  {
    path: '/agencies',
    element: <AuthGuard />,
    children: [
      {
        element: <RoleGuard roles={['ZIGZAG']} />,
        children: [
          {
            index: true,
            element: <AgenciesPage />,
          },
        ],
      },
      {
        path: ':agency_id',
        element: <ResourceGuard />,
        children: [
          {
            element: <NavLayout />,
            children: [
              {
                index: true,
                element: <AgencyHomePage />,
              },
              {
                path: 'dashboard',
                element: <ResourceGuard scopes={['MANAGER']} />,
                children: [
                  {
                    index: true,
                    element: <Navigate to='./advertisement' replace />,
                  },
                  {
                    path: 'advertisement',
                    element: <AdvertisementDashboardPage />,
                  },
                  {
                    path: 'sales',
                    element: <SalesDashboardPage />,
                  },
                ],
              },
              {
                path: 'stores',
                element: <ManageStorePage />,
              },
              {
                path: 'marketers',
                element: <ResourceGuard scopes={['MANAGER']} />,
                children: [
                  {
                    index: true,
                    element: <ManageMarketerPage />,
                  },
                ],
              },
              {
                path: 'settlements',
                element: <ManageSettlementPage />,
              },
              {
                path: 'setting',
                element: <SettingPage />,
              },
              {
                path: 'penalties',
                index: true,
                element: <PenaltiesPage />,
              },
              {
                path: 'notices',
                children: [
                  {
                    index: true,
                    element: <NoticeListPage />,
                  },
                  {
                    path: ':notice_id',
                    element: <NoticeDetailPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
