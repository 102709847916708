import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { flex } from '@/common/styles';

export const Root = styled.div`
  ${flex({ direction: 'column', alignment: 'center', gap: 2 })}
  position: relative;
`;

export const ScoreBoard = styled(motion.div)`
  ${flex({ direction: 'column', alignment: 'center', gap: 2 })}
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
