import { CSSProperties, FC, HTMLAttributes } from 'react';
import { Root } from './SkeletonBase.styled';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  is_loading?: boolean;
}

export const SkeletonBase: FC<Props> = ({ is_loading = true, children, ...rest }) => {
  return is_loading ? <Root {...rest} /> : children;
};
