import { IconCircleQuestion, IconProps, semantic_colors, Tooltip as PDSTooltip } from '@croquiscom/pds';
import { ComponentProps, ComponentType, FC, HTMLAttributes } from 'react';
import { Root } from './Tooltip.styled';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'content'> {
  /**
   * 툴팁 위치
   */
  placement?: ComponentProps<typeof PDSTooltip>['placement'];
  /**
   * 툴팁 컨텐츠
   */
  content: ComponentProps<typeof PDSTooltip>['content'];
  /**
   * 툴팁 컨텐츠
   */
  size?: ComponentProps<typeof PDSTooltip>['size'];
  /**
   * 툴팁 컨텐츠
   */
  kind?: ComponentProps<typeof PDSTooltip>['kind'];
  /**
   * 아이콘 색상
   */
  color?: string;
  /**
   * 노출 할 아이콘
   */
  IconComponent?: ComponentType<IconProps>;
}

export const Tooltip: FC<Props> = ({
  IconComponent = IconCircleQuestion,
  content,
  size,
  kind = 'accent',
  placement,
  color = semantic_colors.content.tertiary,
  ...rest
}) => {
  return (
    <PDSTooltip placement={placement} content={content} size={size} kind={kind}>
      <Root {...rest}>
        <IconComponent size={16} color={color} />
      </Root>
    </PDSTooltip>
  );
};
