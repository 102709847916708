import { colors, semantic_colors } from '@croquiscom/pds';

/**
 * 패널티 점수를 뱃지로 변환 합니다.
 * @param score 패널티 점수
 */
export const penaltyScoreToBadge = (score: number) => {
  if (score < 1) {
    return {
      label: '양호',
      color: semantic_colors.state.positive,
    };
  }
  if (score < 6) {
    return {
      label: '주의',
      color: colors.kakao_yellow700,
    };
  }
  if (score < 8) {
    return {
      label: '경고',
      color: colors.orange700,
    };
  }
  if (score < 10) {
    return {
      label: '위험',
      color: semantic_colors.state.negative,
    };
  }
  return {
    label: '해지',
    color: semantic_colors.state.negative,
  };
};
