import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from 'react-query';
import { gql, GqlRequestHeaders } from './fetcher';
import * as Types from './types';

export const CreateAdAgencyAgencyManagerTypeDocument = `
    mutation CreateAdAgencyAgencyManagerType($input: CreateAdAgencyAgencyManagerTypeInput) {
  create_ad_agency_agency_manager_type: createAdAgencyAgencyManagerType(
    input: $input
  )
}
    `;

export const useCreateAdAgencyAgencyManagerTypeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreateAdAgencyAgencyManagerTypeMutation,
    TError,
    Types.CreateAdAgencyAgencyManagerTypeMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.CreateAdAgencyAgencyManagerTypeMutation,
    TError,
    Types.CreateAdAgencyAgencyManagerTypeMutationVariables,
    TContext
  >(
    ['CreateAdAgencyAgencyManagerType'],
    (variables?: Types.CreateAdAgencyAgencyManagerTypeMutationVariables) =>
      gql<Types.CreateAdAgencyAgencyManagerTypeMutation, Types.CreateAdAgencyAgencyManagerTypeMutationVariables>(
        CreateAdAgencyAgencyManagerTypeDocument,
        variables,
      )(),
    options,
  );
};

export const DeleteAdAgencyAgencyManagerDocument = `
    mutation DeleteAdAgencyAgencyManager($input: DeleteAdAgencyAgencyManagerInput) {
  delete_ad_agency_agency_manager: deleteAdAgencyAgencyManager(input: $input)
}
    `;

export const useDeleteAdAgencyAgencyManagerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteAdAgencyAgencyManagerMutation,
    TError,
    Types.DeleteAdAgencyAgencyManagerMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeleteAdAgencyAgencyManagerMutation,
    TError,
    Types.DeleteAdAgencyAgencyManagerMutationVariables,
    TContext
  >(
    ['DeleteAdAgencyAgencyManager'],
    (variables?: Types.DeleteAdAgencyAgencyManagerMutationVariables) =>
      gql<Types.DeleteAdAgencyAgencyManagerMutation, Types.DeleteAdAgencyAgencyManagerMutationVariables>(
        DeleteAdAgencyAgencyManagerDocument,
        variables,
      )(),
    options,
  );
};

export const InviteAdAgencyMarketerDocument = `
    mutation InviteAdAgencyMarketer($input: InviteAdAgencyMarketerInput) {
  invite_ad_agency_marketer: inviteAdAgencyMarketer(input: $input)
}
    `;

export const useInviteAdAgencyMarketerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.InviteAdAgencyMarketerMutation,
    TError,
    Types.InviteAdAgencyMarketerMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.InviteAdAgencyMarketerMutation,
    TError,
    Types.InviteAdAgencyMarketerMutationVariables,
    TContext
  >(
    ['InviteAdAgencyMarketer'],
    (variables?: Types.InviteAdAgencyMarketerMutationVariables) =>
      gql<Types.InviteAdAgencyMarketerMutation, Types.InviteAdAgencyMarketerMutationVariables>(
        InviteAdAgencyMarketerDocument,
        variables,
      )(),
    options,
  );
};

export const RequestAdAgencyControlStatusDocument = `
    mutation RequestAdAgencyControlStatus($input: RequestAdAgencyControlStatusInput) {
  request_ad_agency_control_status: requestAdAgencyControlStatus(input: $input)
}
    `;

export const useRequestAdAgencyControlStatusMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.RequestAdAgencyControlStatusMutation,
    TError,
    Types.RequestAdAgencyControlStatusMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.RequestAdAgencyControlStatusMutation,
    TError,
    Types.RequestAdAgencyControlStatusMutationVariables,
    TContext
  >(
    ['RequestAdAgencyControlStatus'],
    (variables?: Types.RequestAdAgencyControlStatusMutationVariables) =>
      gql<Types.RequestAdAgencyControlStatusMutation, Types.RequestAdAgencyControlStatusMutationVariables>(
        RequestAdAgencyControlStatusDocument,
        variables,
      )(),
    options,
  );
};

export const RemoveAdAgencyFromSellerAccountDocument = `
    mutation RemoveAdAgencyFromSellerAccount($input: RemoveAdAgencyFromSellerAccountInput!) {
  remove_ad_agency_from_seller_account: removeAdAgencyFromSellerAccount(
    input: $input
  )
}
    `;

export const useRemoveAdAgencyFromSellerAccountMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.RemoveAdAgencyFromSellerAccountMutation,
    TError,
    Types.RemoveAdAgencyFromSellerAccountMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.RemoveAdAgencyFromSellerAccountMutation,
    TError,
    Types.RemoveAdAgencyFromSellerAccountMutationVariables,
    TContext
  >(
    ['RemoveAdAgencyFromSellerAccount'],
    (variables?: Types.RemoveAdAgencyFromSellerAccountMutationVariables) =>
      gql<Types.RemoveAdAgencyFromSellerAccountMutation, Types.RemoveAdAgencyFromSellerAccountMutationVariables>(
        RemoveAdAgencyFromSellerAccountDocument,
        variables,
      )(),
    options,
  );
};

export const DownloadAdAgencyAdAllPerformanceReportListDocument = `
    mutation DownloadAdAgencyAdAllPerformanceReportList($input: DownloadAdAgencyAdAllPerformanceReportListInput!) {
  download_ad_agency_ad_all_performance_report_list: downloadAdAgencyAdAllPerformanceReportList(
    input: $input
  ) {
    presigned_s3_url
  }
}
    `;

export const useDownloadAdAgencyAdAllPerformanceReportListMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DownloadAdAgencyAdAllPerformanceReportListMutation,
    TError,
    Types.DownloadAdAgencyAdAllPerformanceReportListMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DownloadAdAgencyAdAllPerformanceReportListMutation,
    TError,
    Types.DownloadAdAgencyAdAllPerformanceReportListMutationVariables,
    TContext
  >(
    ['DownloadAdAgencyAdAllPerformanceReportList'],
    (variables?: Types.DownloadAdAgencyAdAllPerformanceReportListMutationVariables) =>
      gql<
        Types.DownloadAdAgencyAdAllPerformanceReportListMutation,
        Types.DownloadAdAgencyAdAllPerformanceReportListMutationVariables
      >(DownloadAdAgencyAdAllPerformanceReportListDocument, variables)(),
    options,
  );
};

export const DownloadAdAgencyAdTopStorePerformanceReportListDocument = `
    mutation DownloadAdAgencyAdTopStorePerformanceReportList($input: DownloadAdAgencyAdTopStorePerformanceReportListInput!) {
  download_ad_agency_ad_top_store_performance_report_list: downloadAdAgencyAdTopStorePerformanceReportList(
    input: $input
  ) {
    presigned_s3_url
  }
}
    `;

export const useDownloadAdAgencyAdTopStorePerformanceReportListMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DownloadAdAgencyAdTopStorePerformanceReportListMutation,
    TError,
    Types.DownloadAdAgencyAdTopStorePerformanceReportListMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DownloadAdAgencyAdTopStorePerformanceReportListMutation,
    TError,
    Types.DownloadAdAgencyAdTopStorePerformanceReportListMutationVariables,
    TContext
  >(
    ['DownloadAdAgencyAdTopStorePerformanceReportList'],
    (variables?: Types.DownloadAdAgencyAdTopStorePerformanceReportListMutationVariables) =>
      gql<
        Types.DownloadAdAgencyAdTopStorePerformanceReportListMutation,
        Types.DownloadAdAgencyAdTopStorePerformanceReportListMutationVariables
      >(DownloadAdAgencyAdTopStorePerformanceReportListDocument, variables)(),
    options,
  );
};

export const DownloadAdAgencyAdMarketerPerformanceReportListDocument = `
    mutation DownloadAdAgencyAdMarketerPerformanceReportList($input: DownloadAdAgencyAdMarketerPerformanceReportListInput!) {
  download_ad_agency_ad_marketer_performance_report_list: downloadAdAgencyAdMarketerPerformanceReportList(
    input: $input
  ) {
    presigned_s3_url
  }
}
    `;

export const useDownloadAdAgencyAdMarketerPerformanceReportListMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DownloadAdAgencyAdMarketerPerformanceReportListMutation,
    TError,
    Types.DownloadAdAgencyAdMarketerPerformanceReportListMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DownloadAdAgencyAdMarketerPerformanceReportListMutation,
    TError,
    Types.DownloadAdAgencyAdMarketerPerformanceReportListMutationVariables,
    TContext
  >(
    ['DownloadAdAgencyAdMarketerPerformanceReportList'],
    (variables?: Types.DownloadAdAgencyAdMarketerPerformanceReportListMutationVariables) =>
      gql<
        Types.DownloadAdAgencyAdMarketerPerformanceReportListMutation,
        Types.DownloadAdAgencyAdMarketerPerformanceReportListMutationVariables
      >(DownloadAdAgencyAdMarketerPerformanceReportListDocument, variables)(),
    options,
  );
};

export const DownloadAdAgencySettlementReportListDocument = `
    mutation DownloadAdAgencySettlementReportList($input: DownloadAdAgencySettlementReportListInput!) {
  download_ad_agency_settlement_report_list: downloadAdAgencySettlementReportList(
    input: $input
  ) {
    presigned_s3_url
  }
}
    `;

export const useDownloadAdAgencySettlementReportListMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DownloadAdAgencySettlementReportListMutation,
    TError,
    Types.DownloadAdAgencySettlementReportListMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DownloadAdAgencySettlementReportListMutation,
    TError,
    Types.DownloadAdAgencySettlementReportListMutationVariables,
    TContext
  >(
    ['DownloadAdAgencySettlementReportList'],
    (variables?: Types.DownloadAdAgencySettlementReportListMutationVariables) =>
      gql<
        Types.DownloadAdAgencySettlementReportListMutation,
        Types.DownloadAdAgencySettlementReportListMutationVariables
      >(DownloadAdAgencySettlementReportListDocument, variables)(),
    options,
  );
};

export const GetAdAgencyDocument = `
    query GetAdAgency {
  ad_agency {
    id
    name
    contact_name
    contact_tel
    payment_manager_email
    status
    date_status_changed
    business_license_url
    bankbook_url
    penalty_point
    payment_info {
      bank_code
      account_number
      account_holder
    }
  }
}
    `;

export const useGetAdAgencyQuery = <TData = Types.GetAdAgencyQuery, TError = unknown>(
  variables?: Types.GetAdAgencyQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyQuery, TError, TData>(
    variables === undefined ? ['GetAdAgency'] : ['GetAdAgency', variables],
    gql<Types.GetAdAgencyQuery, Types.GetAdAgencyQueryVariables>(GetAdAgencyDocument, variables),
    options,
  );
};

useGetAdAgencyQuery.getKey = (variables?: Types.GetAdAgencyQueryVariables) =>
  variables === undefined ? ['GetAdAgency'] : ['GetAdAgency', variables];

export const GetAdAgencyListDocument = `
    query GetAdAgencyList {
  ad_agency_list {
    item_list {
      id
      name
      contact_name
      contact_tel
      payment_manager_email
      status
      date_status_changed
      business_license_url
      bankbook_url
      penalty_point
      payment_info {
        bank_code
        account_number
        account_holder
      }
    }
  }
}
    `;

export const useGetAdAgencyListQuery = <TData = Types.GetAdAgencyListQuery, TError = unknown>(
  variables?: Types.GetAdAgencyListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyListQuery, TError, TData>(
    variables === undefined ? ['GetAdAgencyList'] : ['GetAdAgencyList', variables],
    gql<Types.GetAdAgencyListQuery, Types.GetAdAgencyListQueryVariables>(GetAdAgencyListDocument, variables),
    options,
  );
};

useGetAdAgencyListQuery.getKey = (variables?: Types.GetAdAgencyListQueryVariables) =>
  variables === undefined ? ['GetAdAgencyList'] : ['GetAdAgencyList', variables];

export const GetAdAgencyControlStatusDocument = `
    query GetAdAgencyControlStatus($agency_account_id: ID) {
  ad_agency_control_status(agency_account_id: $agency_account_id) {
    item_list {
      shop_id
      shop {
        shop_id
        name
        shop_domain
      }
      status
      date_requested
      date_approved
      date_stopped
      date_rejected
      agency_manager_list {
        id
        shop_manager_id
        name
        phone_number
        identifier
        agency_manager_type
      }
      insight_subscription_name
      shop_promotion_type_list
    }
    transfer_request_list {
      shop_id
      shop {
        shop_id
        name
        shop_domain
      }
      date_requested
    }
  }
}
    `;

export const useGetAdAgencyControlStatusQuery = <TData = Types.GetAdAgencyControlStatusQuery, TError = unknown>(
  variables?: Types.GetAdAgencyControlStatusQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyControlStatusQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyControlStatusQuery, TError, TData>(
    variables === undefined ? ['GetAdAgencyControlStatus'] : ['GetAdAgencyControlStatus', variables],
    gql<Types.GetAdAgencyControlStatusQuery, Types.GetAdAgencyControlStatusQueryVariables>(
      GetAdAgencyControlStatusDocument,
      variables,
    ),
    options,
  );
};

useGetAdAgencyControlStatusQuery.getKey = (variables?: Types.GetAdAgencyControlStatusQueryVariables) =>
  variables === undefined ? ['GetAdAgencyControlStatus'] : ['GetAdAgencyControlStatus', variables];

export const GetAdAgencySalesListDocument = `
    query GetAdAgencySalesList($store_name: String!) {
  ad_agency_sales_list(store_name: $store_name) {
    item_list {
      shop_id
      store_name
      business_number
      is_agency
      is_agency_sales
      is_requested
      is_agency_store
    }
  }
}
    `;

export const useGetAdAgencySalesListQuery = <TData = Types.GetAdAgencySalesListQuery, TError = unknown>(
  variables: Types.GetAdAgencySalesListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencySalesListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencySalesListQuery, TError, TData>(
    ['GetAdAgencySalesList', variables],
    gql<Types.GetAdAgencySalesListQuery, Types.GetAdAgencySalesListQueryVariables>(
      GetAdAgencySalesListDocument,
      variables,
    ),
    options,
  );
};

useGetAdAgencySalesListQuery.getKey = (variables: Types.GetAdAgencySalesListQueryVariables) => [
  'GetAdAgencySalesList',
  variables,
];

export const GetAdAgencyMarketerListDocument = `
    query GetAdAgencyMarketerList {
  ad_agency_marketer_list {
    item_list {
      id
      agency_id
      name
      phone_number
      identifier
      shop_list {
        shop_id
        name
        shop_domain
      }
    }
  }
}
    `;

export const useGetAdAgencyMarketerListQuery = <TData = Types.GetAdAgencyMarketerListQuery, TError = unknown>(
  variables?: Types.GetAdAgencyMarketerListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyMarketerListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyMarketerListQuery, TError, TData>(
    variables === undefined ? ['GetAdAgencyMarketerList'] : ['GetAdAgencyMarketerList', variables],
    gql<Types.GetAdAgencyMarketerListQuery, Types.GetAdAgencyMarketerListQueryVariables>(
      GetAdAgencyMarketerListDocument,
      variables,
    ),
    options,
  );
};

useGetAdAgencyMarketerListQuery.getKey = (variables?: Types.GetAdAgencyMarketerListQueryVariables) =>
  variables === undefined ? ['GetAdAgencyMarketerList'] : ['GetAdAgencyMarketerList', variables];

export const GetAdAgencyPenaltyListDocument = `
    query GetAdAgencyPenaltyList {
  ad_agency_penalty_list {
    item_list {
      id
      penalty_point
      penalty_type
      penalty_type_text
      penalty_sub_type
      penalty_sub_type_text
      memo
      created_at
      created_by
      updated_at
      updated_by
    }
  }
}
    `;

export const useGetAdAgencyPenaltyListQuery = <TData = Types.GetAdAgencyPenaltyListQuery, TError = unknown>(
  variables?: Types.GetAdAgencyPenaltyListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyPenaltyListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyPenaltyListQuery, TError, TData>(
    variables === undefined ? ['GetAdAgencyPenaltyList'] : ['GetAdAgencyPenaltyList', variables],
    gql<Types.GetAdAgencyPenaltyListQuery, Types.GetAdAgencyPenaltyListQueryVariables>(
      GetAdAgencyPenaltyListDocument,
      variables,
    ),
    options,
  );
};

useGetAdAgencyPenaltyListQuery.getKey = (variables?: Types.GetAdAgencyPenaltyListQueryVariables) =>
  variables === undefined ? ['GetAdAgencyPenaltyList'] : ['GetAdAgencyPenaltyList', variables];

export const GetAdAgencyNoticeListDocument = `
    query GetAdAgencyNoticeList($search_text: String, $notice_category: String) {
  ad_agency_notice_list(
    search_text: $search_text
    notice_category: $notice_category
  ) {
    item_list {
      id
      title
      created_at
      date_started
      is_pinned
      notice_category
    }
  }
}
    `;

export const useGetAdAgencyNoticeListQuery = <TData = Types.GetAdAgencyNoticeListQuery, TError = unknown>(
  variables?: Types.GetAdAgencyNoticeListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyNoticeListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyNoticeListQuery, TError, TData>(
    variables === undefined ? ['GetAdAgencyNoticeList'] : ['GetAdAgencyNoticeList', variables],
    gql<Types.GetAdAgencyNoticeListQuery, Types.GetAdAgencyNoticeListQueryVariables>(
      GetAdAgencyNoticeListDocument,
      variables,
    ),
    options,
  );
};

useGetAdAgencyNoticeListQuery.getKey = (variables?: Types.GetAdAgencyNoticeListQueryVariables) =>
  variables === undefined ? ['GetAdAgencyNoticeList'] : ['GetAdAgencyNoticeList', variables];

export const GetAdAgencyNoticeDocument = `
    query GetAdAgencyNotice($notice_id: ID!) {
  ad_agency_notice(notice_id: $notice_id) {
    id
    title
    contents
    notice_status
    is_pinned
    attached_file_list
    created_by
    created_at
    date_started
    display_agency_id_list
    notice_category
  }
}
    `;

export const useGetAdAgencyNoticeQuery = <TData = Types.GetAdAgencyNoticeQuery, TError = unknown>(
  variables: Types.GetAdAgencyNoticeQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyNoticeQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyNoticeQuery, TError, TData>(
    ['GetAdAgencyNotice', variables],
    gql<Types.GetAdAgencyNoticeQuery, Types.GetAdAgencyNoticeQueryVariables>(GetAdAgencyNoticeDocument, variables),
    options,
  );
};

useGetAdAgencyNoticeQuery.getKey = (variables: Types.GetAdAgencyNoticeQueryVariables) => [
  'GetAdAgencyNotice',
  variables,
];

export const GetAdAgencyAdPerformanceInfoDocument = `
    query GetAdAgencyAdPerformanceInfo($date_gte: CrTimestamp!, $date_lte: CrTimestamp!) {
  ad_agency_ad_performance_info(date_gte: $date_gte, date_lte: $date_lte) {
    agency_ad_performance_ranking
    zz_cash_amount
    zz_point_amount
    zz_total_amount
    zz_total_zpay_paid_amount
    fbk_cash_amount
    fbk_point_amount
    fbk_total_amount
    fbk_total_zpay_paid_amount
  }
}
    `;

export const useGetAdAgencyAdPerformanceInfoQuery = <TData = Types.GetAdAgencyAdPerformanceInfoQuery, TError = unknown>(
  variables: Types.GetAdAgencyAdPerformanceInfoQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyAdPerformanceInfoQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyAdPerformanceInfoQuery, TError, TData>(
    ['GetAdAgencyAdPerformanceInfo', variables],
    gql<Types.GetAdAgencyAdPerformanceInfoQuery, Types.GetAdAgencyAdPerformanceInfoQueryVariables>(
      GetAdAgencyAdPerformanceInfoDocument,
      variables,
    ),
    options,
  );
};

useGetAdAgencyAdPerformanceInfoQuery.getKey = (variables: Types.GetAdAgencyAdPerformanceInfoQueryVariables) => [
  'GetAdAgencyAdPerformanceInfo',
  variables,
];

export const GetAdAgencyAdAllPerformanceReportListDocument = `
    query GetAdAgencyAdAllPerformanceReportList($date_gte: CrTimestamp!, $date_lte: CrTimestamp!) {
  ad_agency_ad_all_performance_report_list(
    date_gte: $date_gte
    date_lte: $date_lte
  ) {
    item_list {
      date
      basic_data {
        cash_amount_zz_prod
        point_amount_zz_prod
        cash_amount_zz_att
        point_amount_zz_att
        cash_amount_zz_msg
        point_amount_zz_msg
        cash_amount_fbk_prod
        point_amount_fbk_prod
        zz_total_zpay_paid_amount
        fbk_total_zpay_paid_amount
      }
    }
  }
}
    `;

export const useGetAdAgencyAdAllPerformanceReportListQuery = <
  TData = Types.GetAdAgencyAdAllPerformanceReportListQuery,
  TError = unknown,
>(
  variables: Types.GetAdAgencyAdAllPerformanceReportListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyAdAllPerformanceReportListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyAdAllPerformanceReportListQuery, TError, TData>(
    ['GetAdAgencyAdAllPerformanceReportList', variables],
    gql<Types.GetAdAgencyAdAllPerformanceReportListQuery, Types.GetAdAgencyAdAllPerformanceReportListQueryVariables>(
      GetAdAgencyAdAllPerformanceReportListDocument,
      variables,
    ),
    options,
  );
};

useGetAdAgencyAdAllPerformanceReportListQuery.getKey = (
  variables: Types.GetAdAgencyAdAllPerformanceReportListQueryVariables,
) => ['GetAdAgencyAdAllPerformanceReportList', variables];

export const GetAdAgencyAdTopStorePerformanceReportListDocument = `
    query GetAdAgencyAdTopStorePerformanceReportList($comparison_type: String!, $date_gte: CrTimestamp!, $date_lte: CrTimestamp!) {
  ad_agency_ad_top_store_performance_report_list(
    comparison_type: $comparison_type
    date_gte: $date_gte
    date_lte: $date_lte
  ) {
    item_list {
      shop_id
      shop_name
      vvip_grade
      current_data {
        cash_amount_zz_prod
        point_amount_zz_prod
        cash_amount_zz_att
        point_amount_zz_att
        cash_amount_zz_msg
        point_amount_zz_msg
        cash_amount_fbk_prod
        point_amount_fbk_prod
        zz_total_zpay_paid_amount
        fbk_total_zpay_paid_amount
      }
      past_data {
        cash_amount_zz_prod
        point_amount_zz_prod
        cash_amount_zz_att
        point_amount_zz_att
        cash_amount_zz_msg
        point_amount_zz_msg
        cash_amount_fbk_prod
        point_amount_fbk_prod
        zz_total_zpay_paid_amount
        fbk_total_zpay_paid_amount
      }
    }
  }
}
    `;

export const useGetAdAgencyAdTopStorePerformanceReportListQuery = <
  TData = Types.GetAdAgencyAdTopStorePerformanceReportListQuery,
  TError = unknown,
>(
  variables: Types.GetAdAgencyAdTopStorePerformanceReportListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyAdTopStorePerformanceReportListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyAdTopStorePerformanceReportListQuery, TError, TData>(
    ['GetAdAgencyAdTopStorePerformanceReportList', variables],
    gql<
      Types.GetAdAgencyAdTopStorePerformanceReportListQuery,
      Types.GetAdAgencyAdTopStorePerformanceReportListQueryVariables
    >(GetAdAgencyAdTopStorePerformanceReportListDocument, variables),
    options,
  );
};

useGetAdAgencyAdTopStorePerformanceReportListQuery.getKey = (
  variables: Types.GetAdAgencyAdTopStorePerformanceReportListQueryVariables,
) => ['GetAdAgencyAdTopStorePerformanceReportList', variables];

export const GetAdAgencyAdMarketerPerformanceReportListDocument = `
    query GetAdAgencyAdMarketerPerformanceReportList($comparison_type: String!, $agency_account_id: ID, $date_gte: CrTimestamp!, $date_lte: CrTimestamp!, $page: Int, $page_size: Int) {
  ad_agency_ad_marketer_performance_report_list(
    comparison_type: $comparison_type
    agency_account_id: $agency_account_id
    date_gte: $date_gte
    date_lte: $date_lte
    page: $page
    page_size: $page_size
  ) {
    total_count
    item_list {
      agency_account_id
      marketer_name
      operation_store_cnt
      current_data {
        cash_amount_zz_prod
        point_amount_zz_prod
        cash_amount_zz_att
        point_amount_zz_att
        cash_amount_zz_msg
        point_amount_zz_msg
        cash_amount_fbk_prod
        point_amount_fbk_prod
        zz_total_zpay_paid_amount
        fbk_total_zpay_paid_amount
      }
      past_data {
        cash_amount_zz_prod
        point_amount_zz_prod
        cash_amount_zz_att
        point_amount_zz_att
        cash_amount_zz_msg
        point_amount_zz_msg
        cash_amount_fbk_prod
        point_amount_fbk_prod
        zz_total_zpay_paid_amount
        fbk_total_zpay_paid_amount
      }
    }
  }
}
    `;

export const useGetAdAgencyAdMarketerPerformanceReportListQuery = <
  TData = Types.GetAdAgencyAdMarketerPerformanceReportListQuery,
  TError = unknown,
>(
  variables: Types.GetAdAgencyAdMarketerPerformanceReportListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencyAdMarketerPerformanceReportListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencyAdMarketerPerformanceReportListQuery, TError, TData>(
    ['GetAdAgencyAdMarketerPerformanceReportList', variables],
    gql<
      Types.GetAdAgencyAdMarketerPerformanceReportListQuery,
      Types.GetAdAgencyAdMarketerPerformanceReportListQueryVariables
    >(GetAdAgencyAdMarketerPerformanceReportListDocument, variables),
    options,
  );
};

useGetAdAgencyAdMarketerPerformanceReportListQuery.getKey = (
  variables: Types.GetAdAgencyAdMarketerPerformanceReportListQueryVariables,
) => ['GetAdAgencyAdMarketerPerformanceReportList', variables];

export const GetAdAgencySalesPerformanceReportListDocument = `
    query GetAdAgencySalesPerformanceReportList($date_gte: CrTimestamp!, $date_lte: CrTimestamp!) {
  ad_agency_sales_performance_report_list(
    date_gte: $date_gte
    date_lte: $date_lte
  ) {
    item_list {
      agency_id
      agency_sales_performance_ranking
      new_store_count
      transfer_store_count
      canceled_store_count
    }
  }
}
    `;

export const useGetAdAgencySalesPerformanceReportListQuery = <
  TData = Types.GetAdAgencySalesPerformanceReportListQuery,
  TError = unknown,
>(
  variables: Types.GetAdAgencySalesPerformanceReportListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencySalesPerformanceReportListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencySalesPerformanceReportListQuery, TError, TData>(
    ['GetAdAgencySalesPerformanceReportList', variables],
    gql<Types.GetAdAgencySalesPerformanceReportListQuery, Types.GetAdAgencySalesPerformanceReportListQueryVariables>(
      GetAdAgencySalesPerformanceReportListDocument,
      variables,
    ),
    options,
  );
};

useGetAdAgencySalesPerformanceReportListQuery.getKey = (
  variables: Types.GetAdAgencySalesPerformanceReportListQueryVariables,
) => ['GetAdAgencySalesPerformanceReportList', variables];

export const GetAdAgencySettlementReportListDocument = `
    query GetAdAgencySettlementReportList($agency_account_id: ID, $date_gte: CrTimestamp!, $date_lte: CrTimestamp!, $page: Int, $page_size: Int) {
  ad_agency_settlement_report_list(
    agency_account_id: $agency_account_id
    date_gte: $date_gte
    date_lte: $date_lte
    page: $page
    page_size: $page_size
  ) {
    total_count
    total_result {
      agency_fee
      agency_vat
      shop_total_count
      total_amount
      basic_data {
        cash_amount_zz_prod
        point_amount_zz_prod
        cash_amount_zz_att
        point_amount_zz_att
        cash_amount_zz_msg
        point_amount_zz_msg
        cash_amount_fbk_prod
        point_amount_fbk_prod
        zz_total_zpay_paid_amount
        fbk_total_zpay_paid_amount
      }
    }
    item_list {
      date
      shop_id
      shop_name
      total_amount
      agency_fee
      agency_vat
      basic_data {
        cash_amount_zz_prod
        point_amount_zz_prod
        cash_amount_zz_att
        point_amount_zz_att
        cash_amount_zz_msg
        point_amount_zz_msg
        cash_amount_fbk_prod
        point_amount_fbk_prod
        zz_total_zpay_paid_amount
        fbk_total_zpay_paid_amount
      }
    }
  }
}
    `;

export const useGetAdAgencySettlementReportListQuery = <
  TData = Types.GetAdAgencySettlementReportListQuery,
  TError = unknown,
>(
  variables: Types.GetAdAgencySettlementReportListQueryVariables,
  options?: UseQueryOptions<Types.GetAdAgencySettlementReportListQuery, TError, TData>,
) => {
  return useQuery<Types.GetAdAgencySettlementReportListQuery, TError, TData>(
    ['GetAdAgencySettlementReportList', variables],
    gql<Types.GetAdAgencySettlementReportListQuery, Types.GetAdAgencySettlementReportListQueryVariables>(
      GetAdAgencySettlementReportListDocument,
      variables,
    ),
    options,
  );
};

useGetAdAgencySettlementReportListQuery.getKey = (variables: Types.GetAdAgencySettlementReportListQueryVariables) => [
  'GetAdAgencySettlementReportList',
  variables,
];

export const ChangeSellerAccountInfoDocument = `
    mutation ChangeSellerAccountInfo($input: ChangeSellerAccountInfoInput!) {
  change_seller_account_info: changeSellerAccountInfo(input: $input)
}
    `;

export const useChangeSellerAccountInfoMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.ChangeSellerAccountInfoMutation,
    TError,
    Types.ChangeSellerAccountInfoMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.ChangeSellerAccountInfoMutation,
    TError,
    Types.ChangeSellerAccountInfoMutationVariables,
    TContext
  >(
    ['ChangeSellerAccountInfo'],
    (variables?: Types.ChangeSellerAccountInfoMutationVariables) =>
      gql<Types.ChangeSellerAccountInfoMutation, Types.ChangeSellerAccountInfoMutationVariables>(
        ChangeSellerAccountInfoDocument,
        variables,
      )(),
    options,
  );
};

export function api_createAdAgencyAgencyManagerType(
  variables?: Types.CreateAdAgencyAgencyManagerTypeMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.CreateAdAgencyAgencyManagerTypeMutation, Types.CreateAdAgencyAgencyManagerTypeMutationVariables>(
    CreateAdAgencyAgencyManagerTypeDocument,
    variables,
    options,
  )();
}

export function api_deleteAdAgencyAgencyManager(
  variables?: Types.DeleteAdAgencyAgencyManagerMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.DeleteAdAgencyAgencyManagerMutation, Types.DeleteAdAgencyAgencyManagerMutationVariables>(
    DeleteAdAgencyAgencyManagerDocument,
    variables,
    options,
  )();
}

export function api_inviteAdAgencyMarketer(
  variables?: Types.InviteAdAgencyMarketerMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.InviteAdAgencyMarketerMutation, Types.InviteAdAgencyMarketerMutationVariables>(
    InviteAdAgencyMarketerDocument,
    variables,
    options,
  )();
}

export function api_requestAdAgencyControlStatus(
  variables?: Types.RequestAdAgencyControlStatusMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.RequestAdAgencyControlStatusMutation, Types.RequestAdAgencyControlStatusMutationVariables>(
    RequestAdAgencyControlStatusDocument,
    variables,
    options,
  )();
}

export function api_removeAdAgencyFromSellerAccount(
  variables: Types.RemoveAdAgencyFromSellerAccountMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.RemoveAdAgencyFromSellerAccountMutation, Types.RemoveAdAgencyFromSellerAccountMutationVariables>(
    RemoveAdAgencyFromSellerAccountDocument,
    variables,
    options,
  )();
}

export function api_downloadAdAgencyAdAllPerformanceReportList(
  variables: Types.DownloadAdAgencyAdAllPerformanceReportListMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<
    Types.DownloadAdAgencyAdAllPerformanceReportListMutation,
    Types.DownloadAdAgencyAdAllPerformanceReportListMutationVariables
  >(DownloadAdAgencyAdAllPerformanceReportListDocument, variables, options)();
}

export function api_downloadAdAgencyAdTopStorePerformanceReportList(
  variables: Types.DownloadAdAgencyAdTopStorePerformanceReportListMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<
    Types.DownloadAdAgencyAdTopStorePerformanceReportListMutation,
    Types.DownloadAdAgencyAdTopStorePerformanceReportListMutationVariables
  >(DownloadAdAgencyAdTopStorePerformanceReportListDocument, variables, options)();
}

export function api_downloadAdAgencyAdMarketerPerformanceReportList(
  variables: Types.DownloadAdAgencyAdMarketerPerformanceReportListMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<
    Types.DownloadAdAgencyAdMarketerPerformanceReportListMutation,
    Types.DownloadAdAgencyAdMarketerPerformanceReportListMutationVariables
  >(DownloadAdAgencyAdMarketerPerformanceReportListDocument, variables, options)();
}

export function api_downloadAdAgencySettlementReportList(
  variables: Types.DownloadAdAgencySettlementReportListMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<
    Types.DownloadAdAgencySettlementReportListMutation,
    Types.DownloadAdAgencySettlementReportListMutationVariables
  >(DownloadAdAgencySettlementReportListDocument, variables, options)();
}

export function api_getAdAgency(variables?: Types.GetAdAgencyQueryVariables, options?: GqlRequestHeaders) {
  return gql<Types.GetAdAgencyQuery, Types.GetAdAgencyQueryVariables>(GetAdAgencyDocument, variables, options)();
}

export function api_getAdAgencyList(variables?: Types.GetAdAgencyListQueryVariables, options?: GqlRequestHeaders) {
  return gql<Types.GetAdAgencyListQuery, Types.GetAdAgencyListQueryVariables>(
    GetAdAgencyListDocument,
    variables,
    options,
  )();
}

export function api_getAdAgencyControlStatus(
  variables?: Types.GetAdAgencyControlStatusQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.GetAdAgencyControlStatusQuery, Types.GetAdAgencyControlStatusQueryVariables>(
    GetAdAgencyControlStatusDocument,
    variables,
    options,
  )();
}

export function api_getAdAgencySalesList(
  variables: Types.GetAdAgencySalesListQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.GetAdAgencySalesListQuery, Types.GetAdAgencySalesListQueryVariables>(
    GetAdAgencySalesListDocument,
    variables,
    options,
  )();
}

export function api_getAdAgencyMarketerList(
  variables?: Types.GetAdAgencyMarketerListQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.GetAdAgencyMarketerListQuery, Types.GetAdAgencyMarketerListQueryVariables>(
    GetAdAgencyMarketerListDocument,
    variables,
    options,
  )();
}

export function api_getAdAgencyPenaltyList(
  variables?: Types.GetAdAgencyPenaltyListQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.GetAdAgencyPenaltyListQuery, Types.GetAdAgencyPenaltyListQueryVariables>(
    GetAdAgencyPenaltyListDocument,
    variables,
    options,
  )();
}

export function api_getAdAgencyNoticeList(
  variables?: Types.GetAdAgencyNoticeListQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.GetAdAgencyNoticeListQuery, Types.GetAdAgencyNoticeListQueryVariables>(
    GetAdAgencyNoticeListDocument,
    variables,
    options,
  )();
}

export function api_getAdAgencyNotice(variables: Types.GetAdAgencyNoticeQueryVariables, options?: GqlRequestHeaders) {
  return gql<Types.GetAdAgencyNoticeQuery, Types.GetAdAgencyNoticeQueryVariables>(
    GetAdAgencyNoticeDocument,
    variables,
    options,
  )();
}

export function api_getAdAgencyAdPerformanceInfo(
  variables: Types.GetAdAgencyAdPerformanceInfoQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.GetAdAgencyAdPerformanceInfoQuery, Types.GetAdAgencyAdPerformanceInfoQueryVariables>(
    GetAdAgencyAdPerformanceInfoDocument,
    variables,
    options,
  )();
}

export function api_getAdAgencyAdAllPerformanceReportList(
  variables: Types.GetAdAgencyAdAllPerformanceReportListQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<
    Types.GetAdAgencyAdAllPerformanceReportListQuery,
    Types.GetAdAgencyAdAllPerformanceReportListQueryVariables
  >(GetAdAgencyAdAllPerformanceReportListDocument, variables, options)();
}

export function api_getAdAgencyAdTopStorePerformanceReportList(
  variables: Types.GetAdAgencyAdTopStorePerformanceReportListQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<
    Types.GetAdAgencyAdTopStorePerformanceReportListQuery,
    Types.GetAdAgencyAdTopStorePerformanceReportListQueryVariables
  >(GetAdAgencyAdTopStorePerformanceReportListDocument, variables, options)();
}

export function api_getAdAgencyAdMarketerPerformanceReportList(
  variables: Types.GetAdAgencyAdMarketerPerformanceReportListQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<
    Types.GetAdAgencyAdMarketerPerformanceReportListQuery,
    Types.GetAdAgencyAdMarketerPerformanceReportListQueryVariables
  >(GetAdAgencyAdMarketerPerformanceReportListDocument, variables, options)();
}

export function api_getAdAgencySalesPerformanceReportList(
  variables: Types.GetAdAgencySalesPerformanceReportListQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<
    Types.GetAdAgencySalesPerformanceReportListQuery,
    Types.GetAdAgencySalesPerformanceReportListQueryVariables
  >(GetAdAgencySalesPerformanceReportListDocument, variables, options)();
}

export function api_getAdAgencySettlementReportList(
  variables: Types.GetAdAgencySettlementReportListQueryVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.GetAdAgencySettlementReportListQuery, Types.GetAdAgencySettlementReportListQueryVariables>(
    GetAdAgencySettlementReportListDocument,
    variables,
    options,
  )();
}

export function api_changeSellerAccountInfo(
  variables: Types.ChangeSellerAccountInfoMutationVariables,
  options?: GqlRequestHeaders,
) {
  return gql<Types.ChangeSellerAccountInfoMutation, Types.ChangeSellerAccountInfoMutationVariables>(
    ChangeSellerAccountInfoDocument,
    variables,
    options,
  )();
}
